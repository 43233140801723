import Vue                  from "vue";
import Vuex                 from "vuex";
import createPersistedState from "vuex-persistedstate";

Vue.use(Vuex);

export default new Vuex.Store({
                                plugins  : [createPersistedState()],
                                state    : {
                                  i18n             : "en",
                                  enquiryBasketData: [],
                                  inquiryForm      : {},
                                  roomId           : 0,
                                  roomName         : '',
                                },
                                getters  : {},
                                mutations: {
                                  // 添加询盘篮
                                  AddEnquiryBasket(state, data){
                                    let index = state.enquiryBasketData.findIndex(
                                      (item) => item.id === data.id,
                                    );
                                    if (index !== -1) {
                                      if (state.i18n === "zh") {
                                        alert("记录已存在请勿重复添加");
                                      } else {
                                        alert("If a record already exists, please do not add it repeatedly");
                                      }
                                    } else {
                                      state.enquiryBasketData.push(data);
                                    }
                                  },

                                  // 移除询盘篮
                                  DelEnquiryBasket(state, id){
                                    let index = state.enquiryBasketData.findIndex((item) => item.id === id);
                                    state.enquiryBasketData.splice(index, 1);
                                  },

                                  // 清空询盘篮
                                  ClearEnquiryBasket(state){
                                    state.enquiryBasketData = [];
                                  },

                                  // 切换语言
                                  TranslateLang(state){
                                    state.i18n = state.i18n === "zh" ? "en" : "zh";
                                    location.reload();
                                  },

                                  // 设置展厅
                                  SetShowRoom(state, room){

                                    state.roomId = room.id;
                                    state.roomName = room.room_name;
                                    location.reload();
                                  },

                                  // 暂存询价表单
                                  stagingInquiryForm(state, form){
                                    state.inquiryForm = form;
                                  },
                                },
                                actions  : {},
                                modules  : {},
                              });
