import serviceAxios from "../index";

// 获取面料列表
export const getMaterialList = (data) => {
  return serviceAxios({
    url: "/entity/listingMaterialGroup/search",
    method: "post",
    data,
  });
};
// 获取成衣列表带搜索
export const searchProductList = (data) => {
  return serviceAxios({
    url: "/entity/listingProduct/search",
    method: "post",
    data,
  });
};

/**
 * 获取展厅列表
 * @return {*}
 */
export const getShowRoomList = function(){
  return serviceAxios({
                        url: "/entity/showroom",
                        method: "get",
                      });
}
